import axios from '../utils/axios';

const GetUser = async (serviceToken) => {
    let res;

    try {
        if (serviceToken) {
            res = await axios.get(`/auth/getuserstatus/${serviceToken}`);
        }
        const { data, status } = res;
        res = { success: true, data, status };
    } catch (error) {
        const { data, status } = error;
        res = { success: false, data, status };
    }
    return res;
};

const registerPost = async (inputdata) => {
    let response;
    try {
        const res = await axios.post('/auth/register', JSON.stringify(inputdata), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const { data, status } = res;
        response = { success: true, data, status };
    } catch (error) {
        const { data, status } = error;
        response = { success: false, data, status };
    }
    return response;
};

const loginPost = async (userdata) => {
    let response;
    try {
        const res = await axios.post('/auth/login', JSON.stringify(userdata), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const { data, status } = res;
        response = { success: true, data, status };
    } catch (error) {
        const { data, status } = error;
        response = { success: false, data, status };
    }
    return response;
};

const logoutPost = async (serviceToken) => {
    let response;
    try {
        const res = await axios.post(`/auth/signout/${serviceToken}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const { data, status } = res;
        response = { success: true, data, status };
    } catch (error) {
        const { data, status } = error;
        response = { success: false, data, status };
    }
    return response;
};

const passwordrecoveryPost = async (userdata) => {
    let response;
    try {
        const res = await axios.post(`/auth/passwordrecovery/${userdata.email}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const { data, status } = res;
        response = { success: true, data, status };
    } catch (error) {
        const { data, status } = error;
        response = { success: false, data, status };
    }
    return response;
};

const GetResetPasword = async (token) => {
    let response;
    try {
        const res = await axios.get(`/auth/resetpassword/${token}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const { data, status } = res;
        response = { success: true, data, status };
    } catch (err) {
        console.log(err);

        const { data, status } = err;
        response = { success: false, data, status };
    }
    return response;
};

const resetpasswordPost = async (inputdata) => {
    let response;
    try {
        const res = await axios.post('/auth/resetpassword', JSON.stringify(inputdata), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const { data, status } = res;
        response = { success: true, data, status };
    } catch (error) {
        const { data, status } = error;
        response = { success: false, data, status };
    }
    return response;
};

export { GetUser, registerPost, loginPost, logoutPost, passwordrecoveryPost, GetResetPasword, resetpasswordPost };
