const analytics = {
    id: 'analytics-menu-item',
    title: 'Analytics',
    type: 'group',
    children: [
        {
            id: 'verification',
            title: 'Verification',
            type: 'item',
            url: '/analytics/verification'
        },
        {
            id: 'credits',
            title: 'Top-up credits',
            type: 'item',
            url: '/analytics/credits'
        }
    ]
};

export default analytics;
