const settings = {
    id: 'settings-menu-item',
    title: 'Settings',
    type: 'group',
    children: [
        {
            id: 'jobs',
            title: 'Jobs',
            type: 'item',
            url: '/settings/jobs'
        },
        {
            id: 'configs',
            title: 'Configurations',
            type: 'item',
            url: '/settings/configs'
        },
        {
            id: 'price-list',
            title: 'Price List',
            type: 'item',
            url: '/settings/price-list'
        },
        {
            id: 'business-rules',
            title: 'Business Rules',
            type: 'item',
            url: '/settings/business-rules'
        },
        {
            id: 'payment-methods',
            title: 'Payment Methods',
            type: 'item',
            url: '/settings/payment-methods'
        }
    ]
};

export default settings;
