import { LOGIN, LOGOUT } from 'store/actions';

const defaultState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const AuthReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user
            };
        }
        case LOGOUT: {
            // const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: false,
                isInitialized: true
            };
        }
        default:
            return { ...state };
    }
};

export default AuthReducer;
