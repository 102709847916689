// material-ui
import logo from 'assets/images/cleanverifylogo.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => <img src={logo} alt="CleanVerify " width="120" height="27" />;

export default Logo;
