import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

import useAuth from 'hooks/useAuth';

// sample page routing
const Userlist = Loadable(lazy(() => import('views/admin/user/')));
const FileList = Loadable(lazy(() => import('views/admin/files/')));
const Credits = Loadable(lazy(() => import('views/admin/credits')));
const Error403 = Loadable(lazy(() => import('views/pages/errors/403')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/admin/users',
            element: <Userlist />
        },
        {
            path: '/admin/files',
            element: <FileList />
        },
        {
            path: '/admin/credits',
            element: <Credits />
        }
    ]
};

export default MainRoutes;
