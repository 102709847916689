import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const SettingsJob = Loadable(lazy(() => import('views/admin/settings/Jobs')));
const Configs = Loadable(lazy(() => import('views/admin/settings/Configs')));

// ==============================|| MAIN ROUTING ||============================== //

const SettingRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/settings/jobs',
            element: <SettingsJob />
        },
        {
            path: '/settings/configs',
            element: <Configs />
        }
    ]
};

export default SettingRoutes;
