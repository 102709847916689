// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action - file reducer
export const INITFILE = 'INITFILE';
export const UPLOAD = 'UPLOAD';
export const DELETE = 'DELETE';
export const UPDATE = 'UPDATE';
