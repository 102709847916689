const admin = {
    id: 'admin-menu-item',
    title: 'Admin',
    type: 'group',
    children: [
        {
            id: 'verify',
            title: 'Verify',
            type: 'item',
            url: '/verify'
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/admin/users'
        },
        {
            id: 'admin-files',
            title: 'Files',
            type: 'item',
            url: '/admin/files'
        }
    ]
};

export default admin;
