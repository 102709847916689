import other from './other';
import admin from './admin';
import analytics from './analytics';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (user) => {
    switch (user) {
        case 'Admin':
            return { items: [admin, analytics, settings] };

        default:
            return { items: [other] };
    }
};

export default menuItems;
