import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { Grid, Typography, useMediaQuery } from '@mui/material';

import PageWrapper from '../authentication/AuthWrapper1';
import AuthCardWrapper from '../authentication/AuthCardWrapper';

import Logo from 'ui-component/Logo';
import { fontSize } from '@mui/system';

const ErrorFallback = ({ error }) => {
    ErrorFallback.propTypes = {
        error: PropTypes.object,
        status: PropTypes.number
    };
    console.log(error);
    // const { error } = props;
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    // console.log(props);
    const { status } = error;
    return (
        <PageWrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 100px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <Logo />
                                        </Link>
                                    </Grid>
                                    {status >= 500 && (
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction={matchDownSM ? 'column-reverse' : 'row'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Something went wrong!
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        We are looking into it.
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="16px"
                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                    >
                                                        Server Error, Please try again.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid item container direction="column" alignItems="center" xs={12}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            component={Link}
                                                            to="/"
                                                            onClick={() => {
                                                                window.location.reload();
                                                            }}
                                                        >
                                                            Back to Main Page
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {status === 401 && (
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction={matchDownSM ? 'column-reverse' : 'row'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Not Authenticated
                                                    </Typography>
                                                </Grid>
                                                <Grid item sx={{ py: 2, align: 'center', textAlign: 'center', fontSize: '14px' }}>
                                                    You have an unauthorize request or your session might have expire. Please login.
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid item container direction="column" alignItems="center" xs={12}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            component={Link}
                                                            to="/"
                                                            onClick={() => {
                                                                window.location.reload();
                                                            }}
                                                        >
                                                            Click here to login
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        // <Grid item xs={12}>
                                        //     <Grid
                                        //         container
                                        //         direction={matchDownSM ? 'column-reverse' : 'row'}
                                        //         alignItems="center"
                                        //         justifyContent="center"
                                        //     >
                                        //         <Grid item>
                                        //             <Stack alignItems="center" justifyContent="center" spacing={1}>
                                        //                 <Typography
                                        //                     color={theme.palette.secondary.main}
                                        //                     gutterBottom
                                        //                     variant={matchDownSM ? 'h3' : 'h2'}
                                        //                 >
                                        //                     Not Authenticated
                                        //                 </Typography>
                                        //                 <Typography variant="caption" fontSize="16px" textAlign="center">
                                        //                     You have an unauthorize request or your session might have expire. Please login.
                                        //                 </Typography>
                                        //             </Stack>
                                        //         </Grid>

                                        //         <Grid item xs={12}>
                                        //             <Grid item container direction="column" alignItems="center" xs={12}>
                                        //                 <Typography
                                        //                     component={Link}
                                        //                     to="/login"
                                        //                     variant="subtitle1"
                                        //                     sx={{ textDecoration: 'none' }}
                                        //                 >
                                        //                     Click here to login
                                        //                 </Typography>
                                        //             </Grid>
                                        //         </Grid>
                                        //     </Grid>
                                        // </Grid>
                                    )}
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid> */}
            </Grid>
        </PageWrapper>
    );
};

export default ErrorFallback;
