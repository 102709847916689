const env = process.env.NODE_ENV;

export const DB_API_LIVE =
    env === 'production'
        ? {
              environment: env,
              host: process.env.REACT_APP_CLEANVERIFY_API_URL,
              api: `${process.env.REACT_APP_CLEANVERIFY_API_URL}/api`,
              socketio: process.env.REACT_APP_CLEANVERIFY_WORKER_API_URL,
              clusterapi_url: `${process.env.REACT_APP_CLEANVERIFY_WORKER_API_URL}`,
              clusterapi: `${process.env.REACT_APP_CLEANVERIFY_WORKER_API_URL}/api`
          }
        : {
              environment: env,
              host: process.env.REACT_APP_CLEANVERIFY_API_URL,
              api: `${process.env.REACT_APP_CLEANVERIFY_API_URL}/api`,
              socketio: process.env.REACT_APP_CLEANVERIFY_WORKER_API_URL,
              clusterapi_url: `${process.env.REACT_APP_CLEANVERIFY_WORKER_API_URL}`,
              clusterapi: `${process.env.REACT_APP_CLEANVERIFY_WORKER_API_URL}/api`
          };

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/verify';

const config = {
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
