import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import proptypes from 'prop-types';
import jwtDecode from 'jwt-decode';
// import { useQuery, useMutation } from '@tanstack/react-query';
import { useErrorHandler } from 'react-error-boundary';

// import { registerAPI } from '../utils/auth';
import { GetUser, registerPost, loginPost, logoutPost, passwordrecoveryPost, GetResetPasword, resetpasswordPost } from '../api/auth';
import axios from '../utils/axios';
import { LOGIN, LOGOUT } from 'store/actions';
import AuthReducer from './reducer/AuthReducer';

import Loader from 'ui-component/Loader';

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const AuthCleanVerifyContext = createContext(null);

const AuthCleanVerifyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducer, initialState);
    const handleError = useErrorHandler();
    const navigate = useNavigate();

    const errorHandling = async (data, status) => {
        const errorDetails = { message: data.message, status };

        console.log(errorDetails);
        handleError(errorDetails);
    };

    const logout = async () => {
        const serviceToken = window.localStorage.getItem('serviceToken');

        const { status, data, success } = await logoutPost(serviceToken);
        if (!success) {
            const { message } = data;
            if (status >= 500 || message === 'Network Error') {
                errorHandling(data, status);
            }
        }

        setSession(null);
        dispatch({
            type: LOGOUT
        });
    };

    const authInit = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            if (serviceToken && verifyToken(serviceToken)) {
                setSession(serviceToken);
                const { success, data } = await GetUser(serviceToken);
                if (success) {
                    const { email, fullname, usertype, token, creditamount, creditstatus } = data.user;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: { email, fullname, usertype, token, creditamount, creditstatus }
                        }
                    });
                } else if (!success) {
                    logout();
                }
            } else {
                logout();
            }
        } catch (error) {
            console.log(error);
            dispatch({ type: LOGOUT });
        }
    };

    useEffect(() => {
        authInit();
    }, []);

    const register = async (inputdata) => {
        const { status, data, success } = await registerPost(inputdata);

        if (!success) {
            const { message } = data;
            if (status >= 500 || message === 'Network Error') {
                errorHandling(data, status);
            }
            return { status, data, success };
        }

        const { email, token } = data;
        setSession(token || null);
        window.localStorage.setItem('user', email || null);

        return { status, data, success };
    };

    const login = async (userdata) => {
        const { status, data, success } = await loginPost(userdata);

        if (!success) {
            const { message } = data;
            if (status >= 500 || message === 'Network Error') {
                errorHandling(data, status);
            }
            return { status, data, success };
        }

        const { email, fullname, usertype, token, creditamount, creditstatus } = data.user;
        setSession(token || null);
        window.localStorage.setItem('user', email || null);

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: { email, fullname, usertype, token, creditamount, creditstatus }
            }
        });
        return { status, data, success };
    };

    const passwordrecovery = async (userdata) => {
        const { status, data, success } = await passwordrecoveryPost(userdata);

        if (!success) {
            const { message } = data;
            if (status >= 500 || message === 'Network Error') {
                errorHandling(data, status);
            }
            return { status, data, success };
        }

        return { status, data, success };
    };

    const getResetpassword = async (token) => {
        const { status, data, success } = await GetResetPasword(token);

        if (!success) {
            const { message } = data;
            if (status >= 500 || message === 'Network Error') {
                errorHandling(data, status);
            }

            if (status === 401) return { status, data, success };
        }
        return true;
    };

    const resetpassword = async (userdata) => {
        const { status, data, success } = await resetpasswordPost(userdata);

        if (!success) {
            const { message } = data;
            if (status >= 500 || message === 'Network Error') {
                errorHandling(data, status);
            }
            return { status, data, success };
        }

        return { status, data, success };
    };

    const authcontext = {
        ...state,
        authInit,
        login,
        logout,
        register,
        passwordrecovery,
        getResetpassword,
        resetpassword
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return <AuthCleanVerifyContext.Provider value={authcontext}>{children}</AuthCleanVerifyContext.Provider>;
};

AuthCleanVerifyProvider.propTypes = {
    children: proptypes.node
};

export { AuthCleanVerifyContext, AuthCleanVerifyProvider };
